
import { defineComponent, reactive, ref, toRefs, computed, nextTick, watch } from "vue";
import useMBAFCOverview from "@/hooks/payment/useMBAFCOverview";
import downloadFile from "@/utils/rv/downloadFile";
import { DealerTableData } from "@/views/RetailValidation/Dealer/types";
import { RowSelectionData } from "@/views/Payment/FlRt/types"
import UploadDetail from './uploadDetail.vue'
import { message } from 'ant-design-vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import useWithdrawStatusEnable from '@/hooks/payment/useWithdrawStatusEnable'
import { batchDelete, batchSubmit,batchWidthDraw } from '@/API/payment'
import { useStore } from 'vuex'
type Key = ColumnProps['key'];
import { MBAFCTableData } from "./types";
//import useTableHeight from "@/hooks/useTableHeight";
import ApproveSteps from "@/views/Payment/components/ApproveSteps.vue";
const entityArray = ["FBAC","BBAC","MBCL","DENZA", "全部"]
// const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-380
import GetColumnWidth from "@/utils/payment/getColumnWidth";
export default defineComponent({
  components: {
    UploadDetail,
    ApproveSteps
  },
  setup() {
    
    const store = useStore()
    const current = ref(1)
    // 详情弹窗是否显示
    const isDetailVisible = ref<boolean>(false)
    const entityData = ref<string>("")
    const {queryParams, initSelectedDataForS, getMBAFCInitSelectedDataForSearch,getPayountRoundByYearForSearch, getUploadFileData,isOpen, tableData,attachmentData, total,pictureModal,
      activeKey, mulSearchEvent, downloadImg,
      overviewSerachEvent, pageChange, state, licenseEvent, invoiceEvent, sizeChange, handleClick, handleActiveKey, columns, batchQuery, downloadFilesClick,
      approveVisible, approveClick, approveList} = useMBAFCOverview()
   
    const yearData = initSelectedDataForS.year;
    //#region 列表选择
    const rowsKey = ref<[]>()
    const rows = ref<MBAFCTableData[]>([])
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: MBAFCTableData[]; onChange: Function;columnWidth: string}>({
      onChange: (selectedRowKeys: Key[], selectedRows: MBAFCTableData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '20px'
    })
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page,pageSize,rowSelection)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page,pageSize,rowSelection)
    }
    //#endregion
    //const{tableHeight} = useTableHeight(true,'.overview');
    const hasSelected = computed(() => true)
    watch(
        () => state.year,
        (newVal) => {

         console.log("enter watch........",newVal)
          if (!newVal)
            newVal = ''
            getPayountRoundByYearForSearch(newVal)
        },{
          immediate:false
        }
    );
    const isUploadVisible = ref<boolean>(false)
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      isUploadVisible.value = param.isUploadVisible
    }
    const code = ref<string>('')
    const round = ref<string>('')
    const statusToUpload = ref<string>('')
    const paymentId = ref<string>('')
    //fileUpload function
    const uploadHandle = (record: any) => {
      code.value = record.payoutCode
      round.value = record.payoutRound
      statusToUpload.value = record.status
      paymentId.value = record.id
      getUploadFileData(record.id)
      /*API.getUploadFileData({ params }).then((res) => {
        attachmentData.value = [];
        res.forEach((item, index) => {
          attachmentData.value.push({
            id: index,
            comments: item.comments,
            replyAt: item.replyAt,
          });
        });
        showFailFlag.value = true;
      });*/
      isUploadVisible.value = true
    }
    //#region[操作按钮 撤回 提交 删除]
    const deleteModalVisible = ref<boolean>(false)
    const withdrawModalVisible = ref<boolean>(false)
    const withdrawInstructions = ref<string | null>(null)
    const onWithdraw = () => {
      if (rowSelection.selectedRowData && rowSelection.selectedRowData.length === 0) {
        message.warning('Please select the data first');
      } else {
        if (rowSelection.selectedRowData && useWithdrawStatusEnable(rowSelection.selectedRowData).value) {
          withdrawInstructions.value = null
          withdrawModalVisible.value = true
        } else {
          message.warning('Payout code current status can not withdraw.');
        }
      }
    }
    const onWithdrawConfirm = () => {
      if (!withdrawInstructions.value) {
        message.warning('Reasons for withdrawal are required');
        return false
      }
      const data = rowSelection.selectedRowData.map((item) => {
        return {
          ...item,
          withdrawReason : withdrawInstructions.value
        }
      })
      batchWidthDraw(data).then((res) => {
        if (res.code === '0') {
          message.success('Successfully Withdraw！');
        } else {
          message.error(res.message);
        }
        withdrawModalVisible.value = false
        mulSearchEvent(rowSelection)
      })
    }
    const submitModalVisible = ref<boolean>(false)
    const onSubmit = () => {
      if (rowSelection.selectedRowKeys && rowSelection.selectedRowKeys.length === 0) {
        message.warning('Please select the data first');
      } else {
        const flag = rowSelection.selectedRowData.every((item) => {
          return item.status === 'Created'
        })
        if (flag) {
          submitModalVisible.value = true
        } else {
          message.warning('Only the created state can be submit.');
        }
      }
    }
    const onSubmitConfirm = () => {
      batchSubmit(rowSelection.selectedRowKeys as number[]).then((res) => {
        if (res.code === '0') {
          message.success('Submit succeeded');
        } else {
          message.error(res.message);
        }
        submitModalVisible.value = false
        mulSearchEvent(rowSelection)
      })
    }
    const onDelete = () => {
      if (rowSelection.selectedRowKeys && rowSelection.selectedRowKeys.length === 0) {
        message.warning('Please select the data first.');
      } else {
        const flag = rowSelection.selectedRowData.every((item) => {
          return item.status === 'Created'
        })
        if (flag) {
          deleteModalVisible.value = true
        } else {
          message.warning('Payout code current status can not delete.');
        }
      }
    }
    const onDeleteConfirm = () => {
      batchDelete(rowSelection.selectedRowKeys as number[]).then(() => {
        message.success('Successfully Delete！');
        deleteModalVisible.value = false
        mulSearchEvent(rowSelection)
      }).catch(() => {
        deleteModalVisible.value = false
        mulSearchEvent(rowSelection)
      })

    }
    //#endregion
    // 发票提交和行驶证提交显示
    const isSubVisible = ref<boolean>(false);
    const isInvoice = ref<boolean>(false);
    const isLicense = ref<boolean>(false);
    const selectInfo = reactive([]);
    // 批量上传
    const isBatchVisible = ref<boolean>(false);
    const batchActiveKey = ref<number>(1)
    // 弹窗初始化
    const modalVisible = ref<boolean>(false);
    const modalData = reactive({});
    const params = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });
    const textContentNo = ref<string>("")
    const textNoArray = ref<string[]>([])
    // 批量文本内容
    const textChangEvent = () => {
      textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g,",").split(','))
      const dom = document.getElementsByClassName('list-content')[0]
      const height = dom.scrollHeight
      textContentNo.value = ''
      nextTick(() => { dom.scrollTop = height + 8 })
    }
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
//      state.selectedRowKeys = selectedRowKeys;
     // state.selectedRows = selectedRows;
    }
    const closeModal = (param: { isVisible: boolean }) => {
      modalVisible.value = param.isVisible;
    }
    getMBAFCInitSelectedDataForSearch(store.state.user.fulnm)
    // 关闭批量上传弹窗
    const closeBatch = (param: { isBatchVisible: boolean; isSuccess: boolean }) => {
      isBatchVisible.value = param.isBatchVisible;
      if (param.isSuccess) {
        //getMBAFCInitSelectedDataForSearch();
       //start();
      }
    };
    const getModalInfo = (param: { modalVisible: boolean; modalData: object }) => {
      modalVisible.value = param.modalVisible;
      Object.assign(modalData, param.modalData);
    };
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = false
      batchQuery.batchQueryTitle = '车架号批量查询'
    }
    const editEvent = () => {
      return;
    };
    const recallEvent = () => {
      return;
    };
    const exportResult = () => {
      const params = {
        url: `/rvapi/basic/apply/resultExport`,
        method: 'post',
        params: queryParams,
        fileName: `${+new Date()}.xlsx`
      }
      downloadFile(params)
    }
    // 查看详情
    const detailEvent = (record: DealerTableData) => {
      sessionStorage.setItem("detailRecordId", String(record.id))
      sessionStorage.setItem("detailRecordEntity", record.entity)
      sessionStorage.setItem("detailRecordVinNo", record.vinNo)
      isDetailVisible.value = true
    };
    // 关闭弹窗
    const closeDetail = (param: { isVisible: boolean }) => {
      isDetailVisible.value = param.isVisible;
    }
    // 关闭失败原因弹窗
    const closeFailReason = () => {
      //showFailFlag.value = false
    }
    //    setTimeout(() => {
    //   mulSearchEvent(rowSelection)
    // }, 2000);
    // 表格样式调整
    const tableWidth = ref(GetColumnWidth(columns));
    return {
      //add by renyin
      rowSelection,
      isOpen,
      columns,
      activeKey,
      handleClick,
      handleActiveKey,
      ...toRefs(batchQuery),
      ...toRefs(queryParams),
      ...toRefs(state),
      ...toRefs(pictureModal),
      initSelectedDataForS,
      tableData,
      attachmentData,
      overviewSerachEvent,
      mulSearchEvent,
      entityArray,
      total,
      pageChange,
      sizeChange,
      queryParams,
      exportResult,
      editEvent,
      recallEvent,
      detailEvent,
      isDetailVisible,
      closeDetail,
      closeFailReason,
      entityData,
      //resetEvent,
      licenseEvent,
      invoiceEvent,
      downloadImg,
      hasSelected,
      //start,
      onSelectChange,
      isSubVisible,
      isInvoice,
      isLicense,
      selectInfo,
      tableWidth,
      tableHeight,
      getMBAFCInitSelectedDataForSearch,
      isUploadVisible,
      uploadHandle,
      closeUpload,
      // by hanxi
      withdrawInstructions,
      withdrawModalVisible,
      deleteModalVisible,
      handlePageChange,
      handleSizeChange,
      onDelete,
      onDeleteConfirm,
      onSubmit,
      onWithdraw,
      onWithdrawConfirm,
      modalVisible,
      modalData,
      params,
      getModalInfo,
      closeModal,
      isBatchVisible,
      closeBatch,
      batchActiveKey,
      batchQueryEvent,
      textContentNo,
      textChangEvent,
      textNoArray,
      round,
      code,
      paymentId,
      statusToUpload,
      submitModalVisible,
      onSubmitConfirm,
      downloadFilesClick,
      approveVisible,
      approveClick,
      approveList
    };
  },
});
